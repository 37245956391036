<template>
  <div class="Login blank-page" data-col="blank-page">
    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-cover">
                    <div class="auth-inner row m-0">
                        <!-- Brand logo-->
                        <router-link class="brand-logo" to="/">
                            <center>
                                <img src="/app-assets/images/vakilgiri-logo.png" style="max-width: 150px;">
                            </center>
                            <!-- <h2 class="brand-text text-primary ms-1">Vuexy</h2> -->
                        </router-link>
                        <!-- /Brand logo-->
                        <!-- Left Text-->
                        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
                            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"><img class="img-fluid" src="/app-assets/images/illustration/two-steps-verification-illustration.svg" alt="two steps verification" /></div>
                        </div>
                        <!-- /Left Text-->
                        <!-- two steps verification v2-->
                        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                                <h2 class="card-title fw-bolder mb-1">Two Step Verification &#x1F4AC;</h2>
                                <p class="card-text mb-75">We sent a verification code to your mobile. Enter the code from the mobile in the field below.</p>
                                <p class="card-text fw-bolder mb-2">******0789</p>
                                <form class="mt-2" action="index.html" method="POST">
                                    <h6>Type your 6 digit security code</h6>
                                    <div class="auth-input-wrapper d-flex align-items-center justify-content-between">
                                        <input class="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1" type="text" maxlength="1" autofocus="" />
                                        <input class="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1" type="text" maxlength="1" />
                                        <input class="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1" type="text" maxlength="1" />
                                        <input class="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1" type="text" maxlength="1" />
                                        <input class="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1" type="text" maxlength="1" />
                                        <input class="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1" type="text" maxlength="1" />
                                    </div>
                                    <button class="btn btn-primary w-100" type="submit" tabindex="4">Verify my account</button>
                                </form>
                                <p class="text-center mt-2"><span>Didn&rsquo;t get the code?</span><a href="Javascript:void(0)"><span>&nbsp;Resend</span></a><span>or</span><a href="Javascript:void(0)"><span>&nbsp;Call Us</span></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Content-->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

// export default {
//   name: 'Login',
//   components: {
//     HelloWorld
//   }
// }
</script>
